<template>
    <div>
        <h1 class="section-divider-underline">{{ $t("employee.nssf") }}</h1>
        <div class="row">
            <div class="col-md-6">
                <div class="row mb-3">
                    <label
                        class="form-col-label control-label col-md-5"
                        >{{ $t("employee.nssfNumber") }}</label
                    >
                    <div class="col-md-7">
                        <input
                            type="text"
                            class="form-control"
                            v-model.trim="model.nssf_number"
                            :class="{ 'is-invalid': errors.has('nssf_number') }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('nssf_number')"
                        >
                            {{ errors.first("nssf_number") }}
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <label
                        class="form-col-label control-label col-md-5"
                        >{{ $t("employee.ssnIssuedDate") }}</label
                    >
                    <div class="col-md-7">
                        <DatePicker
                            type="date"
                            :value="model.ssn_issued_date"
                            style="width:100%"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                            @on-change="onChangeSsnIssuedDate"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('ssn_issued_date')"
                        >
                            {{ errors.first("ssn_issued_date") }}
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <label
                        class="form-col-label control-label col-md-5"
                        >{{ $t("employee.ssnIssuedAt") }}</label
                    >
                    <div class="col-md-7">
                        <textarea
                            v-model.trim="model.ssn_issued_at"
                            rows="2"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('ssn_issued_at')
                            }"
                        ></textarea>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('ssn_issued_at')"
                        >
                            {{ errors.first("ssn_issued_at") }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row mb-3">
                    <label
                        class="form-col-label control-label col-md-5"
                        >{{ $t("employee.ssnNo") }}</label
                    >
                    <div class="col-md-7">
                        <input
                            type="text"
                            class="form-control"
                            v-model.trim="model.ssn_no"
                            :class="{ 'is-invalid': errors.has('ssn_no') }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('ssn_no')"
                        >
                            {{ errors.first("ssn_no") }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label
                        class="form-col-label control-label col-md-5"
                        >{{ $t("employee.ssnExpiryDate") }}</label
                    >
                    <div class="col-md-7">
                        <DatePicker
                            type="date"
                            :value="model.ssn_expiry_date"
                            style="width:100%"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                            @on-change="onChangeSsnExpiryDate"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('ssn_expiry_date')"
                        >
                            {{ errors.first("ssn_expiry_date") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
    name: "nssf",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            errors: new Errors()
        };
    },
    created() {
        this.model = this.value;
    },
    methods: {
        onChangeSsnIssuedDate(date){
            this.model.ssn_issued_date = date
        },
        onChangeSsnExpiryDate(date){
            this.model.ssn_expiry_date = date
        },
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        }
    }
};
</script>

<style lang="css" scoped>
.section-divider-underline {
    padding-bottom: 5px;
    margin: 0 0 8px 0;
    /* border-bottom: 1px solid #eee; */
    font-size: 16px;
    color: #929090;
    font-weight: 400;
    text-transform: uppercase;
}
</style>
