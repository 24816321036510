<template>
    <div>
        <h1 class="section-divider-underline">{{ $t('employee.detail') }}</h1>
        <div class="row">
            <div class="col-md-6">
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5 required">
                        {{ $t('employee.status') }}
                    </label>
                    <div class="col-md-7">
                        <select
                            v-model.number="model.status_id"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('status_id') }"
                            @change="clearOption"
                        >
                            <option
                                :value="null"
                                disabled
                                selected
                                style="display: none"
                            >
                                {{ $t('select') }}
                            </option>
                            <option
                                v-for="(st, index) in status"
                                :key="index"
                                :value="st.status_id"
                            >
                                {{ st.status }}
                            </option>
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('status_id')"
                        >
                            {{ errors.first('status_id') }}
                        </div>
                    </div>
                </div>

                <div
                    class="row mb-3"
                    v-if="model.status_id == 2 || model.status_id == 3"
                >
                    <label
                        class="form-label col-form-label col-md-5"
                        :class="{
                            required:
                                model.status_id == 2 || model.status_id == 3
                        }"
                    >
                        {{ $t('employee.statusEffectiveDate') }}
                    </label>
                    <div class="col-md-7">
                        <DatePicker
                            :value="model.status_effective_date"
                            style="width: 100%"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                            @on-change="onChangeEffectiveDate"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('status_effective_date')"
                        >
                            {{ errors.first('status_effective_date') }}
                        </div>
                    </div>
                </div>

                <div
                    class="row mb-3"
                    v-if="model.status_id == 2 || model.status_id == 3"
                >
                    <label
                        class="form-label col-form-label col-md-5"
                        :class="{
                            required:
                                model.status_id == 2 || model.status_id == 3
                        }"
                    >
                        {{ $t('employee.statusReason') }}
                    </label>
                    <div class="col-md-7">
                        <select
                            v-model.number="model.status_reason_id"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('status_reason_id')
                            }"
                        >
                            <option
                                :value="null"
                                disabled
                                selected
                                style="display: none"
                            >
                                {{ $t('select') }}
                            </option>
                            <option
                                v-for="(st, index) in statusReasons"
                                :key="index"
                                :value="st.status_reason_id"
                            >
                                {{ st.reason }}
                            </option>
                        </select>
                        <div
                            class="text-danger"
                            v-if="errors.has('status_reason_id')"
                        >
                            {{ errors.first('status_reason_id') }}
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t('employee.phone')
                    }}</label>
                    <div class="col-md-7">
                        <input
                            type="text"
                            v-model="model.phone"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('phone') }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('phone')"
                        >
                            {{ errors.first('phone') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t('employee.email')
                    }}</label>
                    <div class="col-md-7">
                        <input
                            type="text"
                            v-model="model.email"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('email') }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('email')"
                        >
                            {{ errors.first('email') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">
                        {{ $t('employee.position') }}
                    </label>
                    <div class="col-md-7">
                        <select
                            v-model.number="model.position_id"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('position_id') }"
                        >
                            <option
                                :value="null"
                                disabled
                                selected
                                style="display: none"
                            >
                                {{ $t('select') }}
                            </option>
                            <option
                                v-for="(position, index) in positions"
                                :key="index"
                                :value="position.position_id"
                            >
                                {{ position.position_name_en }}
                            </option>
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('position_id')"
                        >
                            {{ errors.first('position_id') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">{{
                        $t('employee.address')
                    }}</label>
                    <div class="col-md-7">
                        <textarea
                            rows="2"
                            v-model="model.address"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('address') }"
                        ></textarea>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('address')"
                        >
                            {{ errors.first('address') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row mb-3">
                    <label
                        class="form-label col-form-label col-md-5 required"
                        >{{ $t('employee.hiredDate') }}</label
                    >
                    <div class="col-md-7">
                        <DatePicker
                            :value="model.hire_date"
                            style="width: 100%"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                            @on-change="onChangeHireDate"
                        ></DatePicker>
                        <div class="text-danger" v-if="errors.has('hire_date')">
                            {{ errors.first('hire_date') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5 required">
                        {{ $t('employee.probationEndDate') }}
                    </label>
                    <div class="col-md-7">
                        <DatePicker
                            :value="model.probation_end_date"
                            disabled
                            style="width: 100%"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('probation_end_date')"
                        >
                            {{ errors.first('probation_end_date') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label
                        class="form-label col-form-label col-md-5 required"
                        >{{ $t('employee.employeeType') }}</label
                    >
                    <div class="col-md-7">
                        <select
                            v-model.number="model.emp_type_id"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('emp_type_id') }"
                        >
                            <option
                                :value="null"
                                disabled
                                selected
                                style="display: none"
                            >
                                {{ $t('select') }}
                            </option>
                            <option value="1">
                                បុគ្គលិកក្នុងស្រុក | Local
                            </option>
                            <option value="2">ជនបរទេស | Expat</option>
                            <option value="3">
                                បុគ្គលិកឯករាជ្យ | Freelancer
                            </option>
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('emp_type_id')"
                        >
                            {{ errors.first('emp_type_id') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label
                        class="form-label col-form-label col-md-5 required"
                        >{{ $t('employee.staffType') }}</label
                    >
                    <div class="col-md-7">
                        <select
                            v-model.number="model.staff_type_id"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('staff_type_id')
                            }"
                            @change="onChangeStaffType"
                        >
                            <option
                                :value="null"
                                disabled
                                selected
                                style="display: none"
                            >
                                {{ $t('select') }}
                            </option>
                            <option
                                v-for="(staffType, index) in staffTypes"
                                :key="index"
                                :value="staffType.staff_type_id"
                            >
                                {{ staffType.staff_type }}
                            </option>
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('staff_type_id')"
                        >
                            {{ errors.first('staff_type_id') }}
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="form-label col-form-label col-md-5">
                        {{ $t('employee.shift') }}
                    </label>
                    <div class="col-md-7">
                        <select
                            v-model.number="model.shift_id"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('shift_id') }"
                            :disabled="model.staff_type_id == 3"
                        >
                            <option
                                :value="null"
                                disabled
                                selected
                                style="display: none"
                            >
                                {{ $t('select') }}
                            </option>
                            <option
                                v-for="(shift, index) in shifts"
                                :key="index"
                                :value="shift.shift_id"
                            >
                                {{ shift.shift_name }} ({{ shift.shift_type }})
                            </option>
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('shift_id')"
                        >
                            {{ errors.first('shift_id') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import { isEmpty } from 'lodash'

export default {
    name: 'detail',
    props: ['value', 'validation'],
    data () {
        return {
            model: {},
            errors: new Errors(),
            options: null
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('humanResource/employee', [
            'status',
            'statusReason',
            'positions',
            'shifts',
            'nationalities',
            'staffTypes',
            'edit_data'
        ]),
        statusReasons () {
            if (this.model.status_id) {
                return this.statusReason.filter(
                    s => s.status_id == this.model.status_id
                )
            }
            return this.statusReason
        }
    },
    methods: {
        ...mapActions('humanResource/employee', ['getStatusReason']),
        onChangeHireDate (date) {
            this.model.hire_date = date
            this.model.probation_end_date = moment(
                this.model.hire_date,
                'DD-MM-YYYY'
            )
                .add(3, 'M')
                .format('DD-MM-YYYY')
        },
        onChangeEffectiveDate (date) {
            this.model.status_effective_date = date
        },
        onChangeStaffType () {
            if (this.model.staff_type_id == 3) {
                this.model.shift_id = null
            }
        },
        clearOption () {
            this.model.status_reason_id = null
            this.options = null
        }
    },
    watch: {
        'model.status_id': function (value) {
            if ((value == 2) | (value == 3)) {
                this.model.status_effective_date = !isEmpty(this.edit_data)
                    ? this.edit_data.status_effective_date
                    : moment().format('DD-MM-YYYY')

                this.model.status_reason_id = !isEmpty(this.edit_data)
                    ? this.edit_data.status_reason_id
                    : null
            } else {
                this.model.status_effective_date = ''
            }
        },
        validation: {
            handler () {
                this.errors = new Errors(this.validation.errors)
            },
            deep: true
        }
    }
}
</script>

<style lang="css" scoped>
.section-divider-underline {
    padding-bottom: 5px;
    margin: 0 0 8px 0;
    /* border-bottom: 1px solid #eee; */
    font-size: 16px;
    color: #929090;
    font-weight: 400;
    text-transform: uppercase;
}
</style>
